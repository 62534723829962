
export const LAT_LNG = {
  COLOMBO_LIPTON_CIRCUS: [6.91727721553381, 79.86479316713498],
  COLOMBO_THUNMULLA: [6.898934602923438, 79.86047296462549],
  WARWICK: [6.899161363627201, 80.82915891540118],
  PARLIAMENT: [6.886788895029474, 79.91874847062672],

  COLOMBO: [6.9271, 79.8612],
  KANDY: [7.2906, 80.6337],
  KURUNEGALA: [7.4818, 80.3609],
  CENTROID: [7.877217412894828, 80.70049830543581]
};
